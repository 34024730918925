exports.components = {
  "component---src-templates-about-tsx": () => import("./../../../src/.templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-account-activation-tsx": () => import("./../../../src/.templates/account-activation.tsx" /* webpackChunkName: "component---src-templates-account-activation-tsx" */),
  "component---src-templates-affiliate-program-clients-base-tsx": () => import("./../../../src/.templates/affiliate-program-clients-base.tsx" /* webpackChunkName: "component---src-templates-affiliate-program-clients-base-tsx" */),
  "component---src-templates-affiliate-program-dashboard-tsx": () => import("./../../../src/.templates/affiliate-program-dashboard.tsx" /* webpackChunkName: "component---src-templates-affiliate-program-dashboard-tsx" */),
  "component---src-templates-affiliate-program-levels-tsx": () => import("./../../../src/.templates/affiliate-program-levels.tsx" /* webpackChunkName: "component---src-templates-affiliate-program-levels-tsx" */),
  "component---src-templates-affiliate-program-rules-tsx": () => import("./../../../src/.templates/affiliate-program-rules.tsx" /* webpackChunkName: "component---src-templates-affiliate-program-rules-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/.templates/blog-category.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-tag-tsx": () => import("./../../../src/.templates/blog-tag.tsx" /* webpackChunkName: "component---src-templates-blog-tag-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/.templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-cart-tsx": () => import("./../../../src/.templates/cart.tsx" /* webpackChunkName: "component---src-templates-cart-tsx" */),
  "component---src-templates-category-product-listing-tsx": () => import("./../../../src/.templates/category-product-listing.tsx" /* webpackChunkName: "component---src-templates-category-product-listing-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/.templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-custom-tsx": () => import("./../../../src/.templates/custom.tsx" /* webpackChunkName: "component---src-templates-custom-tsx" */),
  "component---src-templates-error-404-tsx": () => import("./../../../src/.templates/error-404.tsx" /* webpackChunkName: "component---src-templates-error-404-tsx" */),
  "component---src-templates-faq-tsx": () => import("./../../../src/.templates/faq.tsx" /* webpackChunkName: "component---src-templates-faq-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/.templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-login-tsx": () => import("./../../../src/.templates/login.tsx" /* webpackChunkName: "component---src-templates-login-tsx" */),
  "component---src-templates-order-login-tsx": () => import("./../../../src/.templates/order-login.tsx" /* webpackChunkName: "component---src-templates-order-login-tsx" */),
  "component---src-templates-order-tsx": () => import("./../../../src/.templates/order.tsx" /* webpackChunkName: "component---src-templates-order-tsx" */),
  "component---src-templates-password-recovery-tsx": () => import("./../../../src/.templates/password-recovery.tsx" /* webpackChunkName: "component---src-templates-password-recovery-tsx" */),
  "component---src-templates-password-reset-tsx": () => import("./../../../src/.templates/password-reset.tsx" /* webpackChunkName: "component---src-templates-password-reset-tsx" */),
  "component---src-templates-people-listing-tsx": () => import("./../../../src/.templates/people-listing.tsx" /* webpackChunkName: "component---src-templates-people-listing-tsx" */),
  "component---src-templates-person-tsx": () => import("./../../../src/.templates/person.tsx" /* webpackChunkName: "component---src-templates-person-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/.templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-product-listing-tsx": () => import("./../../../src/.templates/product-listing.tsx" /* webpackChunkName: "component---src-templates-product-listing-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/.templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-register-tsx": () => import("./../../../src/.templates/register.tsx" /* webpackChunkName: "component---src-templates-register-tsx" */),
  "component---src-templates-result-tsx": () => import("./../../../src/.templates/result.tsx" /* webpackChunkName: "component---src-templates-result-tsx" */),
  "component---src-templates-search-product-listing-tsx": () => import("./../../../src/.templates/search-product-listing.tsx" /* webpackChunkName: "component---src-templates-search-product-listing-tsx" */),
  "component---src-templates-service-category-listing-tsx": () => import("./../../../src/.templates/service-category-listing.tsx" /* webpackChunkName: "component---src-templates-service-category-listing-tsx" */),
  "component---src-templates-service-listing-tsx": () => import("./../../../src/.templates/service-listing.tsx" /* webpackChunkName: "component---src-templates-service-listing-tsx" */),
  "component---src-templates-service-tsx": () => import("./../../../src/.templates/service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */),
  "component---src-templates-thank-you-tsx": () => import("./../../../src/.templates/thank-you.tsx" /* webpackChunkName: "component---src-templates-thank-you-tsx" */),
  "component---src-templates-user-data-tsx": () => import("./../../../src/.templates/user-data.tsx" /* webpackChunkName: "component---src-templates-user-data-tsx" */),
  "component---src-templates-user-newsletter-tsx": () => import("./../../../src/.templates/user-newsletter.tsx" /* webpackChunkName: "component---src-templates-user-newsletter-tsx" */),
  "component---src-templates-user-order-tsx": () => import("./../../../src/.templates/user-order.tsx" /* webpackChunkName: "component---src-templates-user-order-tsx" */),
  "component---src-templates-user-orders-tsx": () => import("./../../../src/.templates/user-orders.tsx" /* webpackChunkName: "component---src-templates-user-orders-tsx" */),
  "component---src-templates-user-password-tsx": () => import("./../../../src/.templates/user-password.tsx" /* webpackChunkName: "component---src-templates-user-password-tsx" */)
}

