import alterpressApi from './base';

import {
    ICreateOrderMutation,
    ICreateOrderResponse,
    IOrder,
    IOrderDraft,
    IValidateOrderMutation,
    IWorkshopOrder,
} from '../../models/order.model';
import { IDelivery } from '../../models/delivery.model';
import { IPaymentProvider } from '../../models/payment-provider.model';

export const orderApi = alterpressApi
    .enhanceEndpoints({
        addTagTypes: ['Order'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getOrders: builder.query<IOrder[], void>({
                query: () => 'account/orders?expand=orderStatus,items,addresses,payments,delivery',
                providesTags: ['Order'],
            }),
            getOrder: builder.query<IOrder, string | undefined | null>({
                query: (uuid) =>
                    `orders/${uuid}?expand=media,orderStatus,items,items.product,items.product.media,items.product.persons,items.variant,items.variant.media,items.variant,addresses,payments,delivery,items.variant.sets`,
                providesTags: ['Order'],
            }),
            getOrderedWorkshop: builder.query<
                IWorkshopOrder,
                { id: string | undefined | null; uid: string | undefined | null }
            >({
                query: ({ id, uid }) =>
                    `products/workshop-owned/${id}/${uid}?expand=media,attributes,persons,order.media,variant,order.addresses`,
                providesTags: ['Order'],
            }),
            createOrder: builder.mutation<ICreateOrderResponse, ICreateOrderMutation>({
                query: (data) => ({
                    url: `orders?expand=payments`,
                    method: 'POST',
                    body: data,
                }),
                invalidatesTags: ['Order'],
            }),
            validateOrder: builder.mutation<IOrderDraft, IValidateOrderMutation>({
                query: (data) => ({
                    url: `orders/validate`,
                    method: 'POST',
                    body: data,
                }),
                invalidatesTags: ['Order'],
            }),
            getOrderDeliveryProviders: builder.query<
                IDelivery[],
                { items: IValidateOrderMutation['items'] }
            >({
                query: (data) => ({
                    url: `orders/available-shipments?expand=media`,
                    method: 'POST',
                    body: data,
                }),
            }),
            getOrderPaymentProviders: builder.query<IPaymentProvider[], void>({
                query: () => `orders/available-payments?expand=media`,
            }),
            retryPayment: builder.mutation<any, string>({
                query: (paymentId) => ({
                    url: `orders/payments/${paymentId}/retry`,
                    method: 'GET',
                }),
            }),
        }),
    });

export const {
    useGetOrderQuery,
    useGetOrdersQuery,
    useGetOrderedWorkshopQuery,
    useGetOrderDeliveryProvidersQuery,
    useGetOrderPaymentProvidersQuery,
    useCreateOrderMutation,
    useValidateOrderMutation,
    useRetryPaymentMutation,
} = orderApi;
